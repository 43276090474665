import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    SelectField,
    SelectInput, DateField, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, BooleanField, BooleanInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
import {GoBackToolbar} from "../Programs/ProgramEdit";


const PagesEdit = (props) => {
    return (
        <Edit title='Edycja'  {...props}>
            <SimpleForm redirect={"/static_pages"}>
                <TextInput source='title' />
                <RichTextInput source="content" label="Treść strony"/>
                <BooleanInput source="isActive" label="Aktywna" />

                {/*<EditButton basePath='/meals' label="Edytuj"/>*/}
                {/*<DeleteButton basePath='/meals' label="Usuń" />*/}
            </SimpleForm>

        </Edit>
    )
}

export default PagesEdit;