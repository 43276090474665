import {BooleanField, BooleanInput, Create, List, SelectInput, SimpleForm, TextField, TextInput} from "react-admin";
import React from "react";
import {GoBackToolbar} from "../Programs/ProgramEdit";


const AdminGroupCreate = (props) => {
    return <Create title='Nowa grupa' {...props}>
        <SimpleForm redirect={'/groups'} toolbar={<GoBackToolbar />}>
            <TextInput source='title' label="Nazwa grupy"/>
            <BooleanInput source={"isUsersAccess"} label={"Dostęp dla użytkowników"} />
            <BooleanInput source={"isGroupsAccess"} label={"Dostęp dla grup"} />
            <BooleanInput source={"isStaticPagesAccess"} label={"Dostęp dla stron statycznych"} />
            <BooleanInput source={"isContentAccess"} label={"Dostęp dla terści"} />
            <BooleanInput source={"isCoursesAccess"} label={"Dostęp do kursów"} />
        </SimpleForm>
    </Create>
}
export  default AdminGroupCreate;