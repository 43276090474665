import React, { cloneElement, useCallback, useMemo, useState } from "react";
import {
  Datagrid,
  DateInput,
  Edit,
  FormTab,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  SelectInput,
  TabbedForm,
  TextInput,
  ListBase,
  Title,
  ListToolbar,
  BulkActionsToolbar,
  Pagination,
  useRecordContext,
  EditButton,
  DeleteButton,
  ReferenceArrayField,
  TopToolbar,
  CreateButton,
  ReferenceField,
  Button,
  NumberField,
  TextField, ReferenceInput, BooleanField, BooleanInput, ReferenceManyField, SimpleForm
} from "react-admin";
import Card from "@material-ui/core/Card";
import { Checkbox, TableCell, TableRow } from "@material-ui/core";
import {Route, useHistory} from "react-router";
import ProgramEdit, {GoBackToolbar} from "../Programs/ProgramEdit";
import StatusButton from "../../components/Buttons/StatusButton";
import StatusField from "../../components/Fields/StatusField";
import CountField from "../../components/Fields/CountField";
import { useDropzone } from "react-dropzone";
import {Add, Cancel} from "@material-ui/icons";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import { pdfjs } from "react-pdf";
import CustomArrayImageField, {CustomImageField} from "../../fields/CustomImageField";
import {useFormState} from "react-final-form";
pdfjs.GlobalWorkerOptions.workerSrc = window.location.origin + "/pdf.worker.js";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function StyledDropzone(props) {
  const [aFiles, setAFiles] = useState(
    props.fileId ? [{ path: props.defaultName }] : undefined
  );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (props.setPdfFile) {
        props.setPdfFile(file);
      }
      // formData.append("tos_file", file);
      // api.addTos(formData).then(response => response.json()).then(data => props.setFileId(data?.data?.id))
    });

    setAFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    multiple: false,
    accept: ".pdf",
  });

  const files = aFiles?.map((file) => (
    <li key={file.path}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {file.path}{" "}
        <Cancel style={{ marginLeft: "6px" }} onClick={() => remove(files)} />
      </div>
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const remove = (file) => {
    setAFiles(undefined);
    if (props.setPdfFile) {
      props.setPdfFile(undefined);
    }
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Upuść plik PDF tutaj.</p>
      </div>
      {aFiles && aFiles.length > 0 && (
        <aside>
          <h4>Dokument</h4>
          <ul>{files}</ul>
        </aside>
      )}
    </div>
  );
}

const ProgramCreateButton = () => {
  const history = useHistory()
  const record = useRecordContext()

  const redirect = () => {
    history.push({pathname: '/chapters/create', state: {courseId: record.id}})
  }
  console.log(history)
  return <Button onClick={redirect} children={<Add/>} label="Nowy program" />
}
// console.log(ProgramCreateButton.history)
const QuestionCreateButton = () => {
  const history = useHistory()
  const record = useRecordContext()

  const redirect = () => {
    history.push({pathname: '/questionnaires/create', state: {courseId: record.id}})
  }

  return <Button onClick={redirect} children={<Add/>} label="Nowe pytanie" />
}

const TestCreateButton = () => {
  const history = useHistory()
  const record = useRecordContext()
  const redirect = () => {
    history.push({pathname: '/questionnaires/create', state: {courseId: record.id}})
  }
  if (record.questionnaire === null) {
    return <Button onClick={redirect} children={<Add/>} label="Nowy test"/>
  } else return  <></>
}

const CoursesListEdit = (props) => {
  const [pdfFile, setPdfFile] = useState(null);

  return (
    <Edit title="Edytuj..." {...props}>
      <TabbedForm redirect={"/courses"} toolbar={<GoBackToolbar />}>
        <FormTab label="Kurs">
          <TextInput source="title" label="Nazwa kursu" />
          <TextInput multiline source="description" label="Opis kursu" />
          <ReferenceInput label={"Zdjęcia potrawy"} source="backgroundImage" reference="attachments">
            <CustomImageField name={'backgroundImage'} />
          </ReferenceInput>
          <NumberInput source='orderCourseType' label="Kolejność kursu"/>
          <BooleanInput source="isActive" label="Aktywny" />
        </FormTab>

        <FormTab label="Program kursu">
          <ProgramCreateButton />
          <ReferenceArrayField
            label="Programy"
            reference="chapters"
            source="chapters"
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="title" />
              <NumberField source="durationTime" label={"Czas trwania"}/>
              <NumberField source="orderType" label={"Kolejność"} sortByOrder="DESC"/>
              {/*<StatusField*/}
              {/*  source="active"*/}
              {/*  label="AKtywny"*/}
              {/*  textAlign={"center"}*/}
              {/*/>*/}
              <StatusButton source={"isActive"} />
              <EditButton />
              <DeleteButton redirect={false}/>
            </Datagrid>
          </ReferenceArrayField>
        </FormTab>
        <FormTab label="Test">
          <TestCreateButton />
          <ReferenceField
            label=""
            reference="questionnaires"
            source="questionnaire"
          >

                <TextField source={"title"} label={"Nazwa testu"}/>




          {/*  <Datagrid>*/}
          {/*    /!*<TextField source="title" />*!/*/}
          {/*    <EditButton redirect={"/xD"} />*/}
          {/*    <DeleteButton />*/}
          {/*</Datagrid>*/}

          </ReferenceField>
        </FormTab>
        <FormTab label="Pdf">
          <StyledDropzone setPdfFile={setPdfFile} />
          <NumberInput label="x" source={"x"} />
          <NumberInput label="y" source={"y"} />
          <Document
            noData={""}
            onLoadError={console.log}
            onLoadSource={console.log}
            renderMode={"canvas"}
            file={pdfFile}
          >
            <Page scale={0.6} pageNumber={1} />
          </Document>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default CoursesListEdit;
