import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useField} from "react-final-form";
import {RecordContextProvider} from "ra-core";
import {pull} from "lodash";
import ImageField from "./ImageField";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


function StyledDropzone(props) {

    const [aFiles, setAFiles] = useState(props.fileId? [{path: props.defaultName}]:undefined)

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const formData = new FormData();
            formData.append("file", file);

            fetch(process.env.REACT_APP_IMAGE_URL+'api/attachments', {
                method: "POST",
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Access-Control-Allow-Origin': window.location.hostname
                },
            }).then(res => res.json()).then(data => props.setFile(data));

        })

        setAFiles(acceptedFiles)

    }, [])


    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,

    } = useDropzone({onDrop: onDrop, multiple: true, accept: 'image/*'});


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);


    return (
        <div className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p>Upuść pliki img tutaj.</p>
            </div>
        </div>
    );
}


const CustomArrayImageField  = (props) => {
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState(props.data)
    const {
        input,
        meta
    } = useField(props.name);


    const remove = (id) => () => {
        input.onChange( pull((input.value || []), id))
        setRefresh(!refresh)
    }


    const setFile = (file) => {
        setData({...data, ['/api/attachments/' + file.id]: file})
        input.onChange([...(input.value || []), '/api/attachments/' + file.id])
    }

    return <>
        {(input.value || []).map((id, key) => <RecordContextProvider value={data[id]} key={key}>
            <ImageField onRemove={remove(id)} source={'contentUrl'}/>
        </RecordContextProvider>)}
        <StyledDropzone setFile={setFile}/>
    </>
}

const CustomImageField  = (props) => {
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState(props.choices)
    const {
        input,
        meta
    } = useField(props.name);


    useEffect(() => {
        setData(props.choices)
    }, [props.choices])

    const remove = (id) => () => {
        input.onChange( null)
        setRefresh(!refresh)
    }


    const setFile = (file) => {
        input.onChange('/api/attachments/' + file.id)
    }


    return <>{input.value && data &&
    <RecordContextProvider value={data.find(d => d.id  == input.value)}>
        <ImageField onRemove={remove(input.value)} source={'contentUrl'}/>
    </RecordContextProvider>}
        <StyledDropzone setFile={setFile}/>
    </>
}

export default CustomArrayImageField
export  {CustomImageField}