import React from "react";
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    EditButton,
    DeleteButton,
    BooleanField,
    SelectField,
    ReferenceInput,
    SelectInput,
    TextInput,
    SimpleList,
    Filter,
    SearchInput, TopToolbar, CreateButton, useRecordContext,
} from "react-admin";
import StatusField from "../../components/Fields/StatusField";
import {useMediaQuery} from "@material-ui/core";


const CoursesFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="title" resettable alwaysOn/>
    </Filter>
);

const CoursesListActions = ({basePath}) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="Kreator kursów"/>
    </TopToolbar>
);

const CoursesList = (props) => {
    const record = useRecordContext();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={<CoursesFilter/>}
            actions={<CoursesListActions/>}
            {...props}
        >
            <Datagrid>
                <TextField source="id"/>
                <TextField source="title" label="Nazwa kursu"/>
                <DateField source="updatedAt" label="Data edycji"/>
                <NumberField source="chaptersCount" label="Ilość rozdziałów"/>
                <NumberField source="orderCourseType" label="Kolejność wyświetlania" />
                <BooleanField source="isActive" label="Aktywny" />
                <EditButton basePath="/courses" label="Edytuj"/>
                <DeleteButton basePath='/courses' label="Usuń"/>
            </Datagrid>
        </List>
    );
};
export default CoursesList;
