import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    TextInput,
    ReferenceInput,
    SearchInput,
    Filter,
    SimpleForm,
    BooleanInput,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField
} from 'react-admin';
import {Button, Typography} from "@material-ui/core";
import {Block} from "@material-ui/icons";
import ViewListIcon from "@material-ui/icons/ViewList";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FieldTitle} from "ra-core";

const AdminListActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="Nowy Admin"/>
    </TopToolbar>
);


const AdminShowButton = ({ record }) => (
    <ShowButton basePath="/admin" label="Podgląd" record={record} />
);

const AdminBlockButton = ({ record }) => (
    <ShowButton basePath="/admin" label="Zablokuj" record={record} />
);

const AdminFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="name" resettable alwaysOn />
    </Filter>
)

const AdminList = (props) => {


    return <List filters={<AdminFilter />} sort={{ field: 'name', order: 'DESC' }} actions={<AdminListActions />} {...props}>
        <Datagrid>
            <TextField source='id' />
            <TextField source="name" label="Imię" />
            <TextField source='surname' label="Nazwisko"/>
            <EmailField source='email' label="Email"/>
            <ReferenceField label="Grupa dostępu" source="group" reference="groups">
                <TextField source="title" />
            </ReferenceField>

            <BooleanField source="isActive" label="Aktywny" />
            <ShowButton basePath={"/users"} />
            <EditButton basePath='/users' />
        </Datagrid>
    </List>
}

const SwitchButton = (props) => {
    return <FormControlLabel
        control={
            <Switch
                color="primary"switch
            />
        }
        label={
            <Typography>Zablokowany?</Typography>
        }
    />
}

export default AdminList;


