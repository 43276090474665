import {
    BooleanField,
    BooleanInput,
    Create,
    List, PasswordInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    required
} from "react-admin";
import React from "react";
import {GoBackToolbar} from "../Programs/ProgramEdit";

const validate = required("Wymagane");

const AdminCreate = (props) => {
    return <Create title='Nowy użytkownik' {...props}>
        <SimpleForm redirect={'/users'}>
            <TextInput validate={validate} source='name' label="Imię"/>
            <TextInput validate={validate} source='surname' label="Nazwisko" />
            <TextInput validate={validate} source='email' label="Email"/>

            <ReferenceInput label="Grupa dostępu" source="group" reference="groups">
                <SelectInput optionText="title" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
}
export  default AdminCreate;