import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    PasswordInput,
    BooleanInput,
    ReferenceInput,
    required, BooleanField
} from 'react-admin';
import {GoBackToolbar} from "../Programs/ProgramEdit";

const validateFirstName = required("Wymagane");

const AdminEdit = (props) => {
    return (
        <Edit title='Edycja admina'  {...props}>
            <SimpleForm redirect={'/users'}>
                <TextInput validate={validateFirstName} source='name' label="Imię"/>
                <TextInput source='surname' label="Nazwisko" />
                <TextInput source='email' label="Email"/>
                <ReferenceInput label="Grupa dostępu" source="group" reference="groups">
                    <SelectInput optionText="title" />
                </ReferenceInput>
            </SimpleForm>

        </Edit>
    )
}

export default AdminEdit;