import React, {useState} from "react";
import {Login, LoginForm, useLogin} from "react-admin";
import {withStyles} from "@material-ui/core/styles";
import {NavLink} from "react-router-dom";

const styles = {
    main: {background: "#333"},
    avatar: {
        background: "url(//cdn.example.com/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 80,
    },
    icon: {display: "none"},
};

const CustomLoginForm = withStyles({
    button: {background: "#F15922"},
})(LoginForm);

const CustomLoginPage = (props) => {
    const login = useLogin();
    const [form, setForm] = useState({
        login: "",
        password: "",
    });

    const handleChangeForm = (field) => (e) => {
        setForm({...form, [field]: e.currentTarget.value});
    };

    const onSubmit = (e) => {
        e.preventDefault();

        login(form.login, form.password);
    };

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                background: "#333",
                display: "flex",
                alignItems: "center",
            }}
        >
            <div style={{margin: "0 auto", maxWidth: "400px",position: "relative"}}>
                <form
                    onSubmit={onSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "100px",
                        background: "#000",
                        borderRadius: "10px",
                    }}
                >
                    <img style={{position: "absolute", top: '-49px'}} src={'/static/img/badg_hover.svg'} alt=""/>
                    <h1 style={{color: "#fff", fontSize: "52px", textAlign: "center"}}>Zmiana hasła</h1>
                    <label>
                        <input
                            style={{
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                            }}
                            value={form.login}
                            onChange={handleChangeForm("login")}
                            type="password"
                        />
                    </label>
                    <label style={{marginTop: "25px"}}>
                        <input
                            style={{
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                            }}
                            onChange={handleChangeForm("password")}
                            type="password"
                        />
                    </label>
                    <button
                        type="submit"
                        style={{
                            cursor: "pointer",
                            background: "#333",
                            padding: "10px 20px",
                            border: "none",
                            color: "#fff",
                            marginTop: "20px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                        }}
                    >
                        Zmień hasło
                    </button>
                </form>
            </div>
        </div>
    );
};

export default withStyles(styles)(CustomLoginPage);
