import React from "react";
import {
    Edit,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
 useRecordContext
} from "react-admin";
import {useHistory} from "react-router";


const QuestionInput = () => {
    const record = useRecordContext();

    if(record.question) {
        return <TextInput source={'question'} type={'hidden'} label={''} />
    }

    return (
        <ReferenceInput
            label="Pytanie"
            reference="questions"
            source="question"
        >
            <SelectInput optionText="question" />
        </ReferenceInput>
    )
}

function AnswerCreate({ onCancel, ...props }) {
  const history = useHistory();


  return (
    <Create {...props}>
      <SimpleForm redirect={history.goBack}>
        <TextInput source="answer" label={"Odpowiedź"} />
        {/*<QuestionInput type={"hidden"}/>*/}
      </SimpleForm>
    </Create>
  );
}

export default AnswerCreate;
