import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    SelectInput,
    SelectField,
    TopToolbar,
    CreateButton,
    ReferenceField,
    FunctionField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const AdminLoginActions = ({basePath}) => (
    <TopToolbar>
    </TopToolbar>
);

 const AdminLogin = (props) => {
    return <List bulkActionButtons={false} {...props}>
        <Datagrid>
            <DateField source='createdAt' label="Data akcji"/>
            <TextField source='ip' label="IP akcji" />
            <ReferenceField label="Admin ID" source="owner" reference="users">
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField label="Admin Imię" source="owner" reference="users">
                <FunctionField render={record => record.name + " " + record.surname} />
            </ReferenceField>
            {/*<MyEditButton basePath='/exercises' label="Edytuj"/>*/}
            {/*<DeleteButton basePath='/exercises' label="Usuń"/>*/}
        </Datagrid>
    </List>
}

export default AdminLogin;