import React from "react";
import {
    Edit,
    NumberInput,
    SaveButton,
    Toolbar,
    ArrayInput,
    DeleteButton,
    SimpleFormIterator,
    SimpleForm,
    ReferenceInput,
    TextInput,
    ReferenceArrayInput,
    useRecordContext,
    Datagrid,
    useReferenceArrayInputContext,
    SelectInput,
    useGetList,
    FormDataConsumer, useGetOne, ReferenceArrayField, TextField, NumberField, EditButton, CreateButton
} from "react-admin";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import {getChoices} from "../Slides/SlidesEdit";
import {Fields} from "../Slides/SlidesCreate";
import {getTypeChoices} from "./ProgramsCreate";
import StatusButton from "../../components/Buttons/StatusButton";
import {Add} from "@material-ui/icons";

export const GoBackToolbar = (props) => {
  const history = useHistory();

  const onSuccess = () => {
    history.goBack();
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        label="Zapisz"
        redirect={false}
        onClick={onSuccess}
        submitOnEnter={true}
      />
      <Button onClick={onSuccess}>Wstecz</Button>
    </Toolbar>
  );
};

export const SlideCreateButton = () => {
    const history = useHistory()
    const record = useRecordContext()

    const redirect = () => {
        history.push({pathname: '/slides/create', state: {programId: record.id}})
    }

    return <Button onClick={redirect}><Add/>Nowy slajd</Button>
}

function ProgramEdit({ onCancel, ...props }) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<GoBackToolbar />}>
        <TextInput source="title" label={"Tytuł"} />
        <NumberInput source="durationTime" label={"Czas (min)"} />
        <NumberInput source="orderType" label={"Kolejność"} />
          <SlideCreateButton/>
          <ReferenceArrayField
              label="Slajdy"
              reference="slides"
              source="slides"
          >
              <Datagrid>
                  <Label/>
                  <NumberField source="orderType" label={"Kolejność"} sortByOrder="DESC"/>
                  <EditButton redirect={"/xD"} />
                  <DeleteButton redirect={false}/>
              </Datagrid>
          </ReferenceArrayField>
      </SimpleForm>
    </Edit>
  );
}

export const Label = props => {
    const record = useRecordContext()

    return <span>{mapSlideType(record['slideType'])}</span>;
}

const mapSlideType = (type) => {
    return getTypeChoices().find(s => s.id == type)?.name

}

export default ProgramEdit;
