import React from "react";
import {
  Edit,
  Create,
  NumberInput,
  SaveButton,
  Toolbar,
  SelectInput,
  DeleteButton,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ReferenceArrayField,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  EditButton,
  useGetList,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  useDataProvider,
  Button,
  SingleFieldList,
  ChipField, FunctionField, useRedirect, useRefresh
} from "react-admin";
import CountField from "../../components/Fields/CountField";
import {useHistory} from "react-router";
import {Fields} from "../Slides/SlidesCreate";
import {getTypeChoices, GoBackToolbar} from "../Programs/ProgramsCreate";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";
import ReferenceIdField from "../../fields/ReferenceIdField";
import {QuestionCreateButton} from "./QuestionnaireEdit";


const TestToolbar = (props) => {
  const history = useHistory();
  const { values } = useFormState();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const dataProvider = useDataProvider()
  const onSuccess = (props) => {
    redirect('/questionnaires/' + encodeURIComponent(props.data.id));
    refresh();
  };
  const goBack = (props) => {
    history.goBack();
  }

  const questions = []

  const save = () => {
  if (values.question) {
    values.question.forEach(question => {
      questions.push(dataProvider.create('questions', {data: {...question}}))

    })
  }

    Promise.all(questions).then(slides => {
      dataProvider.create('questionnaires', {data: {...values, slides: slides.map(s => s.data.id)}}).then(onSuccess)
    })
  }

  return (
      <Toolbar {...props}>
        <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
        <Button onClick={goBack} label={"Wstecz"}/>
      </Toolbar>
  );
};

function QuestionnaireCreate({ onCancel, ...props }) {
  const history = useHistory()
  const courseId = history?.location?.state?.courseId

  return (
    <Create {...props}>
      <SimpleForm toolbar={<TestToolbar/>}>
        <ReferenceIdField name={'course'} source={courseId}/>
        <TextInput source="title" label={"Test"} />
        {/*<QuestionCreateButton />*/}
        {/*<ReferenceArrayField*/}
        {/*    label="Pytania"*/}
        {/*    reference="questions"*/}
        {/*    source="questions"*/}
        {/*>*/}
        {/*  <Datagrid>*/}
        {/*    <TextField source="id" />*/}
        {/*    <TextField source="question" />*/}
        {/*    <NumberField source="questionOrder" label={"Kolejność"} sortByOrder="DESC"/>*/}
        {/*    <ReferenceArrayField label="Odpowiedzi" reference="answers" source="answers">*/}
        {/*      <SingleFieldList>*/}
        {/*        <ChipField source="answer" />*/}
        {/*      </SingleFieldList>*/}
        {/*    </ReferenceArrayField>*/}
        {/*    <ReferenceField label="Poprawna odpowiedź" source="correctAnswer" reference="answers">*/}
        {/*      <FunctionField {...props} render={ rec => rec.answer} />*/}
        {/*    </ReferenceField>*/}

        {/*    <EditButton redirect={"/xD"} />*/}
        {/*    <DeleteButton redirect={false}/>*/}
        {/*  </Datagrid>*/}
        {/*</ReferenceArrayField>*/}

      </SimpleForm>
    </Create>
  );
}

export default QuestionnaireCreate;
