import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    NumberInput,
    BooleanInput,
    useGetList,
    Toolbar, SaveButton
} from 'react-admin';

import {useHistory} from "react-router";
import {Button} from "@material-ui/core";


const GoBackToolbar = (props) => {
    const history = useHistory();
    const groups = useGetList("groups");
    console.log(Object.values(groups.data))
    const onSuccess = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onClick={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
};

const AdminGroupEdit = (props) => {

    return (
        <Edit title='Edycja admina'  {...props}>
            <SimpleForm redirect={'/groups'} toolbar={<GoBackToolbar />}>
                <TextInput source='title' label="Nazwa grupy"/>
                <BooleanInput source={"isUsersAccess"} label={"Dostęp dla użytkowników"} />
                <BooleanInput source={"isGroupsAccess"} label={"Dostęp dla grup"} />
                <BooleanInput source={"isStaticPagesAccess"} label={"Dostęp dla stron statycznych"} />
                <BooleanInput source={"isContentAccess"} label={"Dostęp dla terści"} />
                <BooleanInput source={"isCoursesAccess"} label={"Dostęp do kursów"} />
            </SimpleForm>

        </Edit>
    )
}

export default AdminGroupEdit;