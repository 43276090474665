import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    TextInput,
    ReferenceInput,
    SearchInput,
    Filter
} from 'react-admin';



const AdminFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="name" resettable alwaysOn />
    </Filter>
)


const ContentList = (props) => {

    return <List  filters={<AdminFilter />} sort={{ field: 'name', order: 'DESC' }} {...props}>
        <Datagrid>
            <TextField source='id' />
            <TextField source="content" label="Część treści" />
            <TextField source='type' label="Typ treści"/>
            <EditButton basePath='/contents' />
            {/*<DeleteButton basePath='/contentList' />*/}
        </Datagrid>
    </List>
}
export default ContentList;


