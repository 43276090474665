import React from "react";
import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  EditButton, TopToolbar, CreateButton, NumberField,
} from "react-admin";

const SlidesActions = ({ basePath }) => (
    <TopToolbar>
      <CreateButton basePath={basePath} label="Nowy slajd"/>
    </TopToolbar>
);

function SlidesList(props) {
  return (
    <List actions={<SlidesActions />} {...props}>
      <Datagrid>
        <TextField source={"name"} label={"Nazwa"} />
        <NumberField source="orderType" label={"Kolejność"} sortByOrder="DESC"/>
        <FunctionField label="Typ" render={(record) => record.typeLabel} />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default SlidesList;
