import React from "react";
import {Edit, SimpleForm, TextInput, DateInput, SelectInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {GoBackToolbar} from "../Programs/ProgramEdit";

const ContentEdit = (props) => {
    return (
        <Edit title='Edycja treści'  {...props}>
            <SimpleForm redirect={'/contents'} >
                <TextInput source={"type"} label={"Typ"}/>
                <RichTextInput source='content' label="Edycja treści"/>
            </SimpleForm>

        </Edit>
    )
}

export default ContentEdit;