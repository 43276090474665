import {
    Create,
    List,
    SimpleForm,
    NumberInput,
    DateInput,
    SelectInput,
    TextField,
    TextInput,
    DateField,
    SaveButton,
    NumberField,
    useRedirect,
    useRefresh,
    SelectField,
    ImageField,
    ImageInput,
    TabbedForm,
    FormTab,
    Toolbar,
    ReferenceArrayField, ReferenceField, ReferenceInput
} from "react-admin";
import React from "react";
import {Button} from "@material-ui/core";
import { useHistory } from "react-router";
import CustomArrayImageField from "../../fields/CustomImageField";
import {CustomImageField} from "../../fields/CustomImageField";


export const SaveToolbar = (props) => {
    const redirect = useRedirect();
    const refresh = useRefresh();
    const history = useHistory();

    const onSuccess = (props) => {
        redirect('/courses/' + encodeURIComponent(props.data.id));
        refresh();
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onSuccess={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={goBack}>Wstecz</Button>
        </Toolbar>
    );
};

const required = (message = 'Pole wymagane') =>
    value => value ? undefined : message;
const validateTitle = [required()];


const UserCreate = (props) => {

    return <Create title='Nowy kurs' {...props} >
        <TabbedForm redirect={'/courses'} toolbar={<SaveToolbar />}>
            <FormTab label="Nowy kurs">
                <TextInput source='title' label="Nazwa kursu" validate={validateTitle}/>
                <TextInput multiline source='description' label="Opis kursu" validate={validateTitle}/>
                <ReferenceInput label={"Zdjęcia potrawy"} source="backgroundImage" reference="attachments">
                    <CustomImageField name={'backgroundImage'} />
                </ReferenceInput>
                <NumberInput source='orderCourseType' label="Kolejność kursu"/>
            </FormTab>

            {/*<DateInput source='publishedAt' label="Data dodania kursu" defaultValue={new Date}/>*/}
            {/*<NumberInput source='chapters' label="Ilość rozdziałów"/>*/}
            {/*<DateInput source='addedAt' label="Data dodania" defaultValue={new Date}/>*/}
            {/*<SelectInput source='active' label="Aktywny" choices={[*/}
            {/*    {id: false, name: 'Niekatywny'},*/}
            {/*    {id: true, name: 'Aktywny'},*/}
            {/*        ]}/>*/}
        </TabbedForm>
    </Create>
}
export  default UserCreate;