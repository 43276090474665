import React, {useEffect, useState} from "react";
import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    useGetOne,
    SelectInput,
    ImageInput, ReferenceInput, useDataProvider, Toolbar, NumberField, NumberInput, SaveButton,
} from "react-admin";
import { Button } from "@material-ui/core";

import {useField, useFormState} from "react-final-form";
import RichTextInput from "ra-input-rich-text";
import {CustomImageField} from "../../fields/CustomImageField";
import {getTypeChoices} from "../Programs/ProgramsCreate";
import {Fields as FieldsCreate} from './SlidesEdit'
import ReferenceIdField from "../../fields/ReferenceIdField";
import {useHistory} from "react-router";
import {Save} from "@material-ui/icons";
import Quill from 'quill/core';
import Toolbar2 from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import htmlEditButton from "quill-html-edit-button";

export const getChoices = (data) => {

    return Object.values(data).map((slide) => ({
        id: slide.type,
        name: slide.typeLabel,
    }));
};

export const Fields = (props) => {
    const type = typeof props.value == null? props.record.slideType:props.value || "0"

    switch (type) {
        case "0":
            return (
                <>
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                </>
            );
        case "1":
            return (
                <>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                </>
            );
        case "2":
            return (
                <>
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                </>
            );
        case "3":
            return (
                <>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                    <RichTextInput
                        multiline={true}
                        label={"Informacja"}
                        source={"info"}
                    />
                </>
            );
        case "4":
            return (
                <>
                    <RichTextInput
                        multiline={true}
                        label={"Informacja"}
                        source={"info"}
                    />
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                </>
            );
        case "5":
            return (
                <>
                    <RichTextInput multiline={true} label={"Tekst 1"} source={"txt1"} />
                    <RichTextInput multiline={true} label={"Tekst 2"} source={"txt2"} />
                </>
            );
        case "6":
            return (
                <>
                    <RichTextInput
                        multiline={true}
                        label={"Informacja"}
                        source={"info"}
                    />
                    <RichTextInput multiline={true} label={"Tekst 2"} source={"txt"} />
                </>
            );
        case "7":
            return (
                <>
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                </>
            );
        case "8":
            return (
                <>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                </>
            );
        case "9":
            return (
                <>
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                </>
            );
        case "10":
            return (
                <>
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                </>
            );
        case "11":
            return (
                <>
                    <RichTextInput multiline={true} label={"Tekst"} source={"txt"} />
                    <ReferenceInput label={"Zdjęcie"} source={"photo"} reference="attachments">
                        <CustomImageField name={"photo"}/>
                    </ReferenceInput>
                    <RichTextInput multiline={true} label={"Tekst 1"} source={"txt1"} />
                    <RichTextInput multiline={true} label={"Tekst 2"} source={"txt2"} />
                    <RichTextInput multiline={true} label={"Tekst 3"} source={"txt3"} />
                    <RichTextInput multiline={true} label={"Odpowiedź 1"} source={"txt1Answer"} />
                    <RichTextInput multiline={true} label={"Odpowiedź 2"} source={"txt2Answer"} />
                    <RichTextInput multiline={true} label={"Odpowiedź 3"} source={"txt3Answer"} />
                    <SelectInput source="correctAnswerField" label={"Poprawna odpowiedź"} style={{width: "256px"}} choices={[
                        { id: 'txt1Answer', name: 'Odpowiedź 1' },
                        { id: 'txt2Answer', name: 'Odpowiedź 2' },
                        { id: 'txt3Answer', name: 'Odpowiedź 3' },
                    ]} />
                </>
            );
        default: return <></>
    }
};

const GoBackToolbar = (props) => {
    const history = useHistory();

    const onSuccess = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onClick={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
};


function SlidesCreate(props) {
    const [value, setValue] = useState(null)
    const history = useHistory()
    const programId = history?.location?.state?.programId

    return (
        <Create {...props} >
            <SimpleForm toolbar={<GoBackToolbar/>}>
                <ReferenceIdField name={'chapter'} source={programId}/>
                <SelectInput defaultValue={"0"} onChange={(event) => setValue(event.target.value)} source="slideType" choices={getTypeChoices()} />
                <NumberInput source="orderType" label={"Kolejność"} />
                <Fields key={value+'xD'} value={value} />
            </SimpleForm>
        </Create>
    );
}

export default SlidesCreate;
