import React, {useState} from "react";
import {Create, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar, useGetList,} from "react-admin";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router";
import ReferenceIdField from "../../fields/ReferenceIdField";

export const GoBackToolbar = (props) => {
    const history = useHistory();

    const onSuccess = (props) => {
        history.push('/chapters/' + encodeURIComponent(props.data.id))
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onSuccess={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={goBack}>Wstecz</Button>
        </Toolbar>
    );
};


export const getTypeChoices = () => ([
    {id: "0", name: 'Nagłówek'},
    {id: "1", name: 'Tekst + Zdjecie'},
    {id: "2", name: 'Zdjecie + Tekst'},
    {id: "3", name: 'Tekst + Informacja'},
    {id: "4", name: 'Informacja + Tekst'},
    {id: "5", name: 'Tekst w 2 kolumnach'},
    {id: "6", name: 'Informacja + Cytat'},
    {id: "7", name: 'Zdjecie + Informacja'},
    {id: "8", name: 'Tekst w 1 kolumnie'},
    {id: "9", name: 'Zdjecie'},
    {id: "10", name: 'Czarne tło'},
    {id: "11", name: 'Ćwiczenie'},
])

function ProgramCreate({ onCancel, ...props }) {
    const {data: dataSlides} = useGetList('slides')
    const [value, setValue] = useState(null)
    const history = useHistory()
    const courseId = history?.location?.state?.courseId

    return (
        <Create {...props}>
            <SimpleForm undoable={false} toolbar={<GoBackToolbar />}>
                <ReferenceIdField name={'course'} source={courseId}/>
                <TextInput source="title" label={"Tytuł"} />
                <NumberInput source="durationTime" label={"Czas (min)"} />
                <NumberInput source="orderType" label={"Kolejność"} />
                {/*<ArrayInput source="slides" label={"Slajdy"}>*/}
                {/*    <SimpleFormIterator>*/}

                {/*        <SelectInput defaultValue={"0"} onChange={(event) => setValue(event.target.value)} label={'Typ slajdu'} source="slideType" choices={getTypeChoices()} />*/}
                {/*        <Fields key={value+'xD'} value={value}/>*/}

                {/*    </SimpleFormIterator>*/}
                {/*</ArrayInput>*/}
            </SimpleForm>
        </Create>
    );
}

export default ProgramCreate;
