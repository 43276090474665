import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
    NumberField,
    NumberInput,
    DeleteButton,
    TextField,
    DateField, ImageInput, ImageField, FileInput, SelectInput
} from 'react-admin';


const AdminLoginEdit = (props) => {
    return (
        <Edit title='Edytuj ćwiecznie'  {...props}>
            <SimpleForm>
                <TextInput disabled source='id' />
                <TextInput source='name' label="Nazwa ćwiecznia"/>
                <TextInput multiline source='description' label="Opis ćwiecznia"/>
                <ImageInput source="pictures" label="Zdjęcia ćwiczenia" accept="image/*" placeholder={<p>Dodaj zdjęcie</p>}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <FileInput source="files" label="Film pokazujący ćwiczenie" accept="application/pdf" placeholder={<p>Dodaj film</p>}>
                    <ImageField source="src" title="title" />
                </FileInput>
                <SelectInput source='part' label="Kategoria" choices={[
                    {id: '1', name: 'Barki'},
                    {id: '2', name: 'Nogi'},
                    {id: '3', name: 'Biceps'}
                ]}/>
                {/*<DateInput source='publishedAt' label="Data dodania"/>*/}
            </SimpleForm>

        </Edit>
    )
}

export default AdminLoginEdit;