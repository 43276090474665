import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,
    TextField,
    DateField,
    FileInput,
    ImageField,
    ImageInput, SelectInput
} from 'react-admin';


const AdminLoginCreate = (props) => {
    return (
        <Create title='Stwórz ćwiczenie'  {...props}>
            <SimpleForm redirect={'/exercises'}>
                <TextInput disabled source='id' />
                <TextInput source='name' label="Nazwa ćwiecznia"/>
                <TextInput multiline source='description' label="Opis ćwiecznia"/>
                <ImageInput source="pictures" label="Zdjęcia ćwiczenia" accept="image/*" placeholder={<p>Dodaj zdjęcie</p>}>
                    <ImageField source="src" title="title" />
                </ImageInput>
                <FileInput source="files" label="Film pokazujący ćwiczenie" accept="application/pdf" placeholder={<p>Dodaj film</p>}>
                    <ImageField source="src" title="title" />
                </FileInput>
                <SelectInput source='part' label="Kategoria" choices={[
                    {id: '1', name: 'Barki'},
                    {id: '2', name: 'Nogi'},
                    {id: '3', name: 'Biceps'}
                ]}/>
                {/*<DateInput source='publishedAt' label="Data dodania"/>*/}
            </SimpleForm>

        </Create>
    )
}

export default AdminLoginCreate;