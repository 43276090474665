import React from "react";
import {Edit,Create, SimpleForm, TextInput, DateInput, SelectInput} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const ContentCreate = (props) => {
    return (
        <Create title='Kreator treści'  {...props}>
            <SimpleForm redirect={'/contents'}>
                <TextInput source={"type"} label={"Typ"}/>
                <RichTextInput source='content' label="Edycja treści"/>
            </SimpleForm>

        </Create>
    )
}

export default ContentCreate;