import {dataProviderHydra} from "../App";

const authProvider = {
        // authentication
        login: ({ username, password }) => new Promise((resolve, reject) => {
                fetch(process.env.REACT_APP_API_URL + '/api/auth/login', {
                        method: "POST",
                       headers: {
                            "Content-Type": "application/json",
                                "Access-Control-Allow-Origin": "*",
                            },
                    body: JSON.stringify({
                            email: username,
                            password: password,
                        })
                }).then(response => {


                            if(!response.ok) {
                           return reject('Nieprawidłowe dane logowania')
                            }

                response.json().then(data=> {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refreshToken', data.refresh_token)
                    dataProviderHydra.introspect().then(() => resolve())
                })
            })
    }),
        checkError: (error) => new Promise((resolve, reject) => {
            const status = error.status || error.response.status;

            if(status === 422) {
                return resolve()
            }

            if(status === 404) {
                return resolve()
            }


            if (status === 401 || status === 403) {

                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');

                return reject({redirectTo: '/login', message: false});
            }
            return resolve();
        }),
        checkAuth: () => new Promise((resolve, reject) => {
            const refreshToken = localStorage.getItem('refreshToken')
                if(!refreshToken) return reject()

                fetch(process.env.REACT_APP_API_URL + '/api/auth/refresh_token', {
                        method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                        },
                body: JSON.stringify({
                        refresh_token: refreshToken,
                    })
            }).then(response => {

                        if(!response.ok) {
                           return  reject()
                            }

                        response.json().then(data=> {
                                localStorage.setItem('token', data.token)
                               localStorage.setItem('refreshToken', data.refresh_token)
                                resolve()
                            })
                })

            resolve()
        }),
        logout: () => new Promise((resolve, reject) => {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            resolve()
        }),
        getIdentity: () => { /* ... */ },
        // authorization

            getPermissions: (params) => new Promise((resolve, reject) => {
            resolve()
        }),
    }

    export default authProvider