import React from "react";
import {useDataProvider, useEditContext, useRecordContext} from "react-admin";
import { Button } from "@material-ui/core";
import { Cancel, Check } from "@material-ui/icons";


const center = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function StatusButton({ ...props }) {
  const { source } = props;
  const record = useRecordContext(props);

  const dataProvider = useDataProvider()

  const customAction = () => {
    dataProvider.update(props.basePath.replace("/", ""), {
      id: record.id,
      data: { ...record, [source]: !record[source] },
    });
  };

  return (
    <Button
      color={record[source] ? "primary" : "success"}
      onClick={customAction}
    >
      {record[source] ? (
        <div style={center}>
          <Cancel style={{ marginRight: "5px" }} /> DEZAKTYWUJ
        </div>
      ) : (
        <div style={center}>
          <Check style={{ marginRight: "5px" }} /> AKTYWUJ
        </div>
      )}
    </Button>
  );
}

export default StatusButton;
