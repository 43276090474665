import React, {useState} from "react";
import {NavLink} from "react-router-dom";


const styles = {
    main: {background: "#333"},
    avatar: {
        background: "url(//cdn.example.com/background.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        height: 80,
    },
    icon: {display: "none"},
};


function ResetPassword({}) {
    const [form, setForm] = useState({
        login: "",
    });

    const [status, setStatus] = useState(undefined);
    const [error, setError] = useState(undefined);

    const handleChangeForm = (field) => (e) => {
        setForm({...form, [field]: e.currentTarget.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "/api/reset_password/create_request", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: form.login,
                    redirectUrl: window.origin + '/password-forgot'
                })
            })

            if (!response.ok) {
                setError({ type: 'error' })
                setStatus({ type: undefined })
            }
            else {
                setStatus({ type: 'success' })
                setError({ type: undefined })
            }
        } catch (e){
            console.log(e)
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                background: "#333",
                display: "flex",
                alignItems: "center",
            }}
        >
            <div style={{margin: "0 auto", maxWidth: "400px", position: "relative"}}>
                <form
                    onSubmit={onSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "100px",
                        background: "#000",
                        borderRadius: "10px",
                    }}
                >
                    <img style={{position: "absolute", top: '-49px'}} src={'/static/img/badg_hover.svg'} alt=""/>
                    <h1 style={{color: "#fff", fontSize: "35px", textAlign: 'center'}}>Podaj adres e-mail</h1>
                    <label>
                        <input
                            style={{
                                border: "none",
                                borderRadius: "5px",
                                padding: "10px 20px",
                            }}
                            value={form.login}
                            onChange={handleChangeForm("login")}
                            type="email"
                        />
                    </label>
                    {status?.type === 'success' && <p style={{color: "green", fontSize:"12px", textAlign: "center"}}>Na podany adres została wysłana wiadomość z linkiem do resetowania hasła</p>}
                    {error?.type === 'error' && <p style={{color: "red", fontSize:"12px", textAlign: "center"}}>Nie ma takiego użytkownika</p>}
                    <button
                        type="submit"
                        style={{
                            cursor: "pointer",
                            background: "#333",
                            padding: "10px 20px",
                            border: "none",
                            color: "#fff",
                            marginTop: "20px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                        }}
                    >
                        Wyślij
                    </button>
                    <NavLink style={{marginTop: "20px", color: "#ff0000"}} to="/login">
                        Zaloguj
                    </NavLink>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;
