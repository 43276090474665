import React, {useEffect, useState} from "react";
import {
    Admin,
    AppBar,
    Layout,
    Resource,
    Sidebar,
    RouteWithoutLayout,
    UserMenu,
    fetchUtils,
    getResources,
    useAuthProvider
} from "react-admin";
import {NavLink} from 'react-router-dom'
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ViewListIcon from "@material-ui/icons/ViewList";
import {Redirect, Route, useLocation} from 'react-router'
import CoursesList from "./containers/CoursesList/coursesList";
import CoursesListCreate from "./containers/CoursesList/coursesListCreate";
import CoursesListEdit from "./containers/CoursesList/coursesListEdit";
import Pages from "./containers/Pages/pages";
import PagesCreate from "./containers/Pages/pagesCreate";
import PagesEdit from "./containers/Pages/pagesEdit";
import AdminList from "./containers/Admin/adminList";
import AdminCreate from "./containers/Admin/adminCreate";
import AdminEdit from "./containers/Admin/adminEdit";
import AdminLogin from "./containers/AdminLogin/adminLogin";
import AdminLoginCreate from "./containers/AdminLogin/adminLoginCreate";
import AdminLoginEdit from "./containers/AdminLogin/adminLoginEdit";
import AdminGroup from "./containers/AdminGroup/adminGroup";
import AdminGroupEdit from "./containers/AdminGroup/adminGroupEdit";
import AdminGroupCreate from "./containers/AdminGroup/adminGroupCreate";
import ContentList from "./containers/WebContent/contentList";
import ContentEdit from "./containers/WebContent/contentEdit";
import {groupBy} from 'lodash';
import ProgramEdit from "./containers/Programs/ProgramEdit";
import Loginpage from "./containers/Loginpage";
import authProvider from "./providers/authProvider";
import ResetPassword from "./containers/ResetPassword";
import ForgotPassword from "./containers/ForgotPassword";
import AnswerEdit from "./containers/Answer/AnswerEdit";
import QuestionEdit from "./containers/Question/QuestionEdit";
import SlidesList from "./containers/Slides/SlidesList";
import SlidesEdit from "./containers/Slides/SlidesEdit";
import SlidesCreate from "./containers/Slides/SlidesCreate";
import ContentCreate from "./containers/WebContent/contentCreate";
import ProgramCreate from "./containers/Programs/ProgramsCreate";
import {useSelector} from 'react-redux';
import {default as baseFetchHydra} from './providers/fetchHydra';
import {default as baseHydraDataProvider} from './providers/dataProvider';
import { useIntrospection } from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import AdminShow from "./containers/Admin/adminShow";
import {Collapse, List, ListItem, ListItemIcon, ListItemText, Typography, withStyles} from "@material-ui/core";
import {Accessibility, ExpandLess, ExpandMore} from '@material-ui/icons';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import polishMessages from "./translation/polishMessages";
import QuestionCreate from "./containers/Question/QuestionCreate";
import QuestionList from "./containers/Question/QuestionList";
import AnswerList from "./containers/Answer/AnswerList";
import AnswerCreate from "./containers/Answer/AnswerCreate";
import QuestionnaireList from "./containers/Questionnaire/QuestionnaireList";
import QuestionnaireCreate from "./containers/Questionnaire/QuestionnaireCreate";
import QuestionnaireEdit from "./containers/Questionnaire/QuestionnaireEdit";


const messages = {
    'pl': polishMessages
};


const i18nProvider = polyglotI18nProvider(() => polishMessages, 'pl');




const useStyles = makeStyles({
    avatar: {
        height: 30,
        width: 30,
    },
});

const theme = createMuiTheme({
    palette: {
        type: "light",
        primary: {
            main: "#f1562f",
            background: "#ffa84c",
        },
        secondary: {
            main: "#ffa84c",
        },
    },
    navbar: {
        primary: "#ffa84c",
    },
    header: {
        backgroundColor: "#ff0000",
    },
    sidebar: {
        width: 300,
        backgroundColor: "#000000",
        drawerPaper: {
            backgroundColor: "#000000",
        },
    },
});

const useMenuStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    group: {
        fontWeight: 'bold',
    },
    active: {
        fontWeight: 'bold',
        color: '#f4a84c'
    }
}));

function NestedList({value, keyVal}) {
    const [open, setOpen] = useState(false)
    const classes = useMenuStyles();
    const Icon = 'icon' in value[0].options? value[0].options.icon : () => <></>
    const location = useLocation();
    return <><ListItem className={classes.group} button onClick={() => setOpen(!open)}>
        <ListItemIcon>
            <Icon/>
        </ListItemIcon>
        <ListItemText  classes={{
            primary: classes.group
        }} primary={keyVal} />
        {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

                {value.map((item, i) => <ListItem component={NavLink} to={'/'+item.name} className={classes.nested} key={i} button>
                    <ListItemIcon>
                        {item.icon && <item.icon />}
                    </ListItemIcon>
                    <ListItemText classes={{
                        primary: location.pathname.includes(item.name)? classes.active:''
                    }} primary={item.options.label} />
                </ListItem>)}

            </List>
        </Collapse>
    </>

}

const MyMenu = props => {
    const resources = useSelector(getResources);
    const classes = useMenuStyles();
    const groupedRes = groupBy(resources.filter(r => r.options.label), 'options.group')
    const location = useLocation();

    return <div>
        <List
        component="nav"
        className={classes.root}
    >
        {Object.entries(groupedRes).map(([key, val]) => {
            if(key == 'undefined'){
                return val.map((item, i) => <ListItem key={i} button component={NavLink} to={'/'+item.name}>
                    <ListItemIcon>
                        {item.icon && <item.icon />}
                    </ListItemIcon>
                    <ListItemText classes={{
                        primary: location.pathname.includes(item.name)? classes.active + ' ' +classes.group:classes.group
                    }} primary={item.options.label} />
                </ListItem>)
            }
            else {
                return <NestedList key={key} value={val} keyVal={key}/>
            }
        })}
    </List>
    </div>
}

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomAppBar = withStyles(styles)(({ classes, userMenu, ...props }) => {
    const authProvider = useAuthProvider();
    return (
        <AppBar userMenu={userMenu || !!authProvider} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />
        </AppBar>
    );
});


const MyLayout = props => <Layout {...props} appBar={CustomAppBar} menu={MyMenu}/>

const MyCustomIcon = () => {
    const classes = useStyles();
    return (
        <Avatar
            className={classes.avatar}
            src="https://marmelab.com/images/avatars/adrien.jpg"
        />
    );
};

const useSidebarStyles = makeStyles({
    drawerPaper: {
        backgroundColor: "#2F3438",
        color: "#ffffff",
    },
});

const MySidebar = (props) => {
    const classes = useSidebarStyles();
    return <Sidebar classes={classes} {...props} />;
};



const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};


const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
        return { api };
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin} />
                ],
            };
        }

        throw result;
    }
};

export const dataProviderHydra = baseHydraDataProvider(process.env.REACT_APP_API_URL + "/api", fetchHydra, apiDocumentationParser);

function App() {
    const [loaded, setLoaded] = useState(false)

    authProvider.checkAuth().then(() => setTimeout(() => dataProviderHydra.introspect().then(() => setLoaded(true)), 200)).catch(() => {
        setLoaded(true)
    })

    return !loaded? <></>:(
        <>
            <Admin
                i18nProvider={i18nProvider}
                locale="pl" messages={messages}
                loading={loaded}
                authProvider={authProvider}
                layout={MyLayout}
                dataProvider={dataProviderHydra}
                customRoutes={[
                    <RouteWithoutLayout
                        noLayout={true}
                        path="/password-reset"
                        exact={true}
                        component={ResetPassword}
                    />,
                    <RouteWithoutLayout
                        noLayout={true}
                        exact={true}
                        path="/password-forgot"
                        component={ForgotPassword}
                    />,
                ]}
                loginPage={Loginpage}
                theme={theme}
            >
                <Resource
                    options={{label: "Lista kursów"}}
                    name="courses"
                    list={CoursesList}
                    create={CoursesListCreate}
                    edit={CoursesListEdit}
                    icon={ViewListIcon}
                />
                <Resource
                    options={{label: "Lista stron statycznych"}}
                    name="static_pages"
                    list={Pages}
                    create={PagesCreate}
                    edit={PagesEdit}
                    icon={ViewListIcon}
                />
                <Resource
                    options={{label: "Lista adminów"}}
                    name="users"
                    list={AdminList}
                    create={AdminCreate}
                    edit={AdminEdit}
                    show={AdminShow}
                    icon={ViewListIcon}
                />
                <Resource
                    options={{label: "Lista logowań adminów"}}
                    name="user_login_logs"
                    list={AdminLogin}
                    create={AdminLoginCreate}
                    edit={AdminLoginEdit}
                    icon={ViewListIcon}
                />
                <Resource
                    options={{label: "Lista grup adminów"}}
                    name="groups"
                    list={AdminGroup}
                    create={AdminGroupCreate}
                    edit={AdminGroupEdit}
                    icon={ViewListIcon}
                />
                <Resource
                    options={{label: "Lista treści"}}
                    name="contents"
                    list={ContentList}
                    create={ContentCreate}
                    edit={ContentEdit}
                    icon={ViewListIcon}
                />
                <Resource
                    edit={SlidesEdit}
                    create={SlidesCreate}
                    name="slides"
                />
                <Resource edit={ProgramEdit} create={ProgramCreate} name="chapters"/>
                <Resource edit={QuestionnaireEdit} create={QuestionnaireCreate} name="questionnaires"/>
                <Resource name="questions" edit={QuestionEdit} create={QuestionCreate} />
                <Resource name="answers"  edit={AnswerEdit} create={AnswerCreate}/>
                <Resource name="attachments" />
                <Resource name="mail_queues" />
            </Admin>
        </>
    );
}

export default App;
