import React from "react";
import {
    Edit,
    Create,
    NumberInput,
    SaveButton,
    Toolbar,
    SelectInput,
    DeleteButton,
    ReferenceArrayInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceArrayField,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    EditButton, useGetList, SelectArrayInput, useDataProvider,Button
} from "react-admin";
import CountField from "../../components/Fields/CountField";
import {useHistory} from "react-router";
import ReferenceIdField from "../../fields/ReferenceIdField";
import {useFormState} from "react-final-form";
import {Save} from "@material-ui/icons";
import {CreateButton} from "./QuestionEdit";



const GoBackToolbar = (props) => {
    const history = useHistory();

    const onSuccess = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onSuccess={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
};

const QuestionToolbar = (props) => {
    const history = useHistory();
    const { values } = useFormState();
    const dataProvider = useDataProvider()

    const onSuccess = () => {
        history.goBack();
    };

    const answers = []

    const save = () => {
        if (values.answers) {
            values.answers.forEach(answer => {
                answers.push(dataProvider.create('answers', {data: {...answer}}))

            })
        }

        Promise.all(answers).then(slides => {
            dataProvider.create('questions', {data: {...values, slides: slides.map(s => s.data.id)}}).then(() => onSuccess())
        })
    }

    return (
        <Toolbar {...props}>
            <Button onClick={save} children={<Save/>} label="Zapisz" variant={"contained"}/>
            <Button onClick={onSuccess} label={"Wstecz"}/>
        </Toolbar>
    );
};

function QuestionCreate({onCancel, ...props}) {
    const history = useHistory()
    const courseId = history?.location?.state?.questionnaireId
    console.log(courseId)
    return (
        <Create {...props}>
            <SimpleForm toolbar={<QuestionToolbar/>}>
                <ReferenceIdField name={'questionnaire'} source={courseId}/>
                <TextInput source="question" label={"Pytanie"}/>
                <NumberInput label={"Kolejność"} source={"questionOrder"}/>
                {/*<ReferenceArrayField*/}
                {/*    label="Odpowiedzi"*/}
                {/*    reference="answers"*/}
                {/*    source="answers"*/}
                {/*>*/}
                {/*    <>*/}
                {/*        <Datagrid>*/}
                {/*            <TextField label="Odpowiedź" source={"answer"} />*/}
                {/*            <NumberField label="Kolejność" source={"order"} />*/}

                {/*            <EditButton />*/}
                {/*        </Datagrid>*/}
                {/*        <CreateButton />*/}
                {/*    </>*/}
                {/*</ReferenceArrayField>*/}

            </SimpleForm>
        </Create>
    );
}

export default QuestionCreate;
