import React from "react";
import {
    NumberInput,
    Edit,
    SimpleForm,
    TextInput,
    TextField,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    Toolbar, SaveButton, Button
} from "react-admin";
import {useHistory} from "react-router";
import {GoBackToolbar} from "../Programs/ProgramEdit";


function AnswerEdit(props) {
  return (
    <Edit {...props}>
        <SimpleForm toolbar={<GoBackToolbar />}>
          <TextInput source="answer" label="Odpowiedź"/>
          {/*<ReferenceInput label="Pytanie" source="question" reference="questions">*/}
          {/*    <SelectInput optionText="question" />*/}
          {/*</ReferenceInput>*/}
      </SimpleForm>
    </Edit>
  );
}

export default AnswerEdit;
