import React from 'react'
import {SelectInput, useRecordContext} from "react-admin";

function FilteredSelcetInput({...props}) {
    const record = useRecordContext(props)

    const choices = props.choices.filter(choice => props.filter(choice, record))

    return <SelectInput {...props} choices={choices}  />
}


export default FilteredSelcetInput;
