import React from "react";
import {
    Edit,
    NumberInput,
    SaveButton,
    Toolbar,
    SelectInput,
    DeleteButton,
    ReferenceArrayInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceArrayField,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    EditButton, useRecordContext,
} from "react-admin";
import FilteredSelcetInput from "../../fields/FilteredSelcetInput";
import { Link } from "react-router-dom";
import { Add } from "@material-ui/icons";
import Button from '@material-ui/core/Button';
import {useHistory} from "react-router";

export const CreateButton = (props) => {
    const record = useRecordContext();
    return (
        <Button
            component={Link}
            to={{
                pathname: '/answers/create',
                state: {record: {
                    question: record.id
                }},
            }}
        >
            <Add/>
            Dodaj odpowiedź
        </Button>
    )
}

const CorrectAnswerField = (props) => {
    const record = useRecordContext();
    if(!record.answers || !record.answers.length) return <></>

    return <ReferenceInput
        label={"Poprawna odpowiedź"}
        source="correctAnswer"
        reference="answers"
        perPage={1000000}
        {...props}
    >
        <FilteredSelcetInput filter={(choice, record) => record.answers.includes(choice.id)} optionText={"answer"} />
    </ReferenceInput>
}

const GoBackToolbar = (props) => {
    const history = useHistory();

    const onSuccess = () => {
        history.goBack();
    };

    return (
        <Toolbar {...props}>
            <SaveButton
                label="Zapisz"
                redirect={false}
                onClick={onSuccess}
                submitOnEnter={true}
            />
            <Button onClick={onSuccess}>Wstecz</Button>
        </Toolbar>
    );
};

function QuestionEdit({ onCancel, ...props }) {
  return (

    <Edit {...props}>
      <SimpleForm redirect={"/questions"} toolbar={<GoBackToolbar />}>
        <TextInput source="question" label={"Pytanie"} />

        <CorrectAnswerField />


        <NumberInput label={"Kolejność"} source={"questionOrder"} />

        <ReferenceArrayField
          label="Odpowiedzi"
          reference="answers"
          source="answers"
        >
          <>
            <Datagrid>
              <TextField label="Odpowiedź" source={"answer"} />
              {/*<NumberField label="Kolejność" source={"order"} />*/}

              <EditButton />
            </Datagrid>
              <CreateButton />
          </>
        </ReferenceArrayField>
      </SimpleForm>
    </Edit>
  );
}

export default QuestionEdit;
