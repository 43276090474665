import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    TextInput,
    ReferenceInput,
    SearchInput,
    Filter, NumberField, useListContext
} from 'react-admin';



const AdminGroupFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="name" resettable alwaysOn />
    </Filter>
)

const AdminGroupActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="Nowa grupa"/>
    </TopToolbar>
);

const AdminGroup = (props) => {

    return <List filters={<AdminGroupFilter />} sort={{ field: 'name', order: 'DESC' }} {...props} actions={<AdminGroupActions />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="title" label="Nazwa grupy" />
            <NumberField source='userCount' label="Ilość adminów w grupie"/>

            <EditButton basePath='/groups' />
            <DeleteButton basePath='/groups'/>
        </Datagrid>
    </List>
}

export default AdminGroup;


