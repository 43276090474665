import React from "react";
import {
  Edit,
  NumberInput,
  SaveButton,
  Toolbar,
  SelectInput,
  DeleteButton,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ReferenceArrayField,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  EditButton,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  useRecordContext,
  SingleFieldList,
  ChipField,
  FunctionField,
} from "react-admin";
import CountField from "../../components/Fields/CountField";

import FilteredSelcetInput from "../../fields/FilteredSelcetInput";
import {Fields} from "../Slides/SlidesCreate";
import {getTypeChoices} from "../Programs/ProgramsCreate";
import {useHistory} from "react-router";
import {Button} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import StatusButton from "../../components/Buttons/StatusButton";


export const QuestionCreateButton = () => {
  const history = useHistory()
  const record = useRecordContext()
  const redirect = () => {
    history.push({pathname: '/questions/create', state: {questionnaireId: record.id}})
  }

  return <Button onClick={redirect}><Add/>Nowe pytanie</Button>
}

const GoBackToolbar = (props) => {
  const history = useHistory();

  const onSuccess = () => {
    history.go(-2);
  };

  return (
      <Toolbar {...props}>
        <SaveButton
            label="Zapisz"
            redirect={false}
            onClick={onSuccess}
            submitOnEnter={true}
        />
        <Button onClick={onSuccess}>Wstecz</Button>
      </Toolbar>
  );
};


function QuestionnaireEdit({ onCancel, ...props }) {

  return (

    <Edit {...props}>
      <SimpleForm redirect={"/questions"} toolbar={<GoBackToolbar />}>
        <TextInput source={"title"} label={"Nazwa testu"}/>
        <QuestionCreateButton />
        <ReferenceArrayField
            label="Pytania"
            reference="questions"
            source="questions"
        >
          <Datagrid>
            <TextField source="id" />
            <TextField source="question" />
            <NumberField source="questionOrder" label={"Kolejność"} sortByOrder="DESC"/>
            <ReferenceArrayField label="Odpowiedzi" reference="answers" source="answers">
              <SingleFieldList>
                <ChipField source="answer" />
              </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceField label="Poprawna odpowiedź" source="correctAnswer" reference="answers">
              <FunctionField {...props} render={ rec => rec.answer} />
            </ReferenceField>

            <EditButton redirect={"/xD"} />

          </Datagrid>
        </ReferenceArrayField>

        {/*<TextInput source="title" label={"Nazwa testu"} />*/}
        {/*<ArrayInput source="questions" label={"Pytania"}>*/}
        {/*  <SimpleFormIterator>*/}
        {/*    <TextInput source={"question"} label={"Pytanie"}/>*/}
        {/*    <AnswerCreateButton />*/}
        {/*    <ReferenceArrayInput*/}
        {/*        label="Odpowiedzi"*/}
        {/*        reference="answers"*/}
        {/*        source="answer"*/}
        {/*    >*/}
        {/*      <Datagrid>*/}
        {/*        <TextInput source={"answer"}/>*/}
        {/*        <EditButton redirect={"/xD"} />*/}
        {/*        <DeleteButton redirect={false}/>*/}
        {/*      </Datagrid>*/}
        {/*    </ReferenceArrayInput>*/}
        {/*    <SelectInput source={"correctAnswer"} label={"Poprawna odpowiedź"} />*/}
        {/*  </SimpleFormIterator>*/}
        {/*</ArrayInput>*/}

      </SimpleForm>
    </Edit>
  );
}

export default QuestionnaireEdit;
