import React from "react";
import { useRecordContext } from "react-admin";
import { Check, Cancel } from "@material-ui/icons";

const center = {
  textAlign: "center",
};

function StatusField(props) {
  const { source } = props;
  const record = useRecordContext(props);

  if (record[source]) {
    return (
      <div style={center}>
        <Check />
      </div>
    );
  }
  return (
    <div style={center}>
      <Cancel />
    </div>
  );
}

export default StatusField;
