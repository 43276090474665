import React from "react";
import {
    Show,
    Datagrid,
    TextField,
    DateField,
    SelectField,
    SelectInput,
    EmailField,
    BooleanField,
    EditButton,
    DeleteButton,
    ShowButton,
    CreateButton,
    TopToolbar,
    TextInput,
    ReferenceInput,
    SearchInput,
    Filter,
    SimpleForm,
    BooleanInput,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    SimpleShowLayout
} from 'react-admin';
import {Button, Typography} from "@material-ui/core";
import {Block} from "@material-ui/icons";
import ViewListIcon from "@material-ui/icons/ViewList";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FieldTitle} from "ra-core";



const AdminShowButton = ({ record }) => (
    <ShowButton basePath="/admin" label="Podgląd" record={record} />
);

const AdminBlockButton = ({ record }) => (
    <ShowButton basePath="/admin" label="Zablokuj" record={record} />
);

const AdminFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="name" resettable alwaysOn />
    </Filter>
)

const AdminShow = (props) => {


    return <Show {...props}>
        <SimpleShowLayout>
            <TextField source='id' />
            <TextField source="name" label="Imię" />
            <TextField source='surname' label="Nazwisko"/>
            <EmailField source='email' label="Email"/>
            <ReferenceField label="Grupa dostępu" source="group" reference="groups">
                <TextField source="title" />
            </ReferenceField>
            <BooleanField source="isActive" label="Aktywny" />
        </SimpleShowLayout>
    </Show>
}

export default AdminShow;


