import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    EditButton,
    DeleteButton,
    EmailField,
    SelectField,
    RichTextField,
    TopToolbar,
    CreateButton,
    TextInput, Filter, SearchInput, BooleanField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';



const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
        fontSize: '45px',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange' }
    },
});

const MyEditButton = props => {
    const classes = useStyles();
    // return <EditButton className={classes.button} {...props} />;
    return <EditButton {...props} />;
};

const PagesListActions = ({ basePath }) => (
    <TopToolbar>
        <CreateButton basePath={basePath} label="Nowa strona"/>
    </TopToolbar>
);

const PagesFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="title" resettable alwaysOn />
    </Filter>
)

 const Pages = (props) => {
    return <List filters={<PagesFilter />} sort={{ field: 'title', order: 'DESC' }} actions={<PagesListActions />} {...props}>
        <Datagrid>
            <TextField source='id' />
            <TextField source='title' label="Tytuł strony" />
            <DateField source="updatedAt" label="Data edycji strony"/>
            <BooleanField source="isActive" label="Aktywna" />
            <EditButton basePath='/static_pages' label="Edytuj"/>
            {/*<DeleteButton basePath='/pages' label="Usuń" />*/}
        </Datagrid>
    </List>
}

export default Pages;